import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";

import esflag from "../media/us.svg";
import enflag from "../media/es.svg";
import Catarsis from "../media/Group 11.svg";

import { Container } from "@mui/system";
import { Context } from "../App";
import { useTranslation } from "react-i18next";

import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { useState, useEffect } from "react";

//test 2
export default function Navbar() {
  const [t, i18n] = useTranslation("global");
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const isMobile = useMediaQuery("(max-width: 960px)");

  const handleLanguageChange = () => {
    const newLanguage = currentLanguage === "en" ? "es" : "en";
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const [mobileMenu, setMobileMenu] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.type === "Tab" || event.type === "Shift")
    ) {
      return;
    }

    setMobileMenu({ ...mobileMenu, [anchor]: open });
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace("/blog", "").replace("/shop", "");
    window.history.replaceState(null, "", newUrl);
  }, []);

  const menuItems = [
    {
      text: t("navbar.home"),
      href: "#heroSection",
    },
    {
      text: t("navbar.weAre"),
      href: "#weAre",
    },
    {
      text: t("navbar.experiences"),
      href: "#experience",
    },
    {
      text: t("navbar.thisIsForYou"),
      href: "#thisIsForYou",
    },
    {
      text: t("navbar.store"),
      href: "https://shop.mycatarsis.com/",
    },
    {
      text: t("navbar.blog"),
      href: "/blog",
    },
    {
      text: t("navbar.language"),
      onClick: handleLanguageChange,
    },
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width: "100vw",
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 1) 39.14%, rgba(241, 251, 255, 1) 100%), linear-gradient(0deg, rgba(241, 251, 255, 1), rgba(255, 255, 255, 1))",
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {isMobile && (
          <div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "5px"
              }}>
              <CloseIcon fontSize="large" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Catarsis}
                alt="Catarsis"
                style={{ width: "60%", margin: "15px" }}
              />
            </div>
          </div>
        )}
        {menuItems.map((item, index) => (
          <ListItem key={index}>
            <ListItemButton
              component="a"
              href={item.href}
              onClick={item.onClick}
            >
              {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
              <ListItemText
                primary={item.text}
                sx={{ display: "flex", justifyContent: "center" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const NavLink = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#000000",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "&:hover": {
      color: "#11a2bb",
    },
  }));

  const NavbarLinksBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "2.5rem",
    color: "#000000",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: "pointer",
    display: "none",
    marginRight: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      gap: theme.spacing(3),
      margin: theme.spacing(1, 0, 1, "45%"),
      fontSize: 30,
    },
  }));

  const NavbarContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#F3FBFF",
    marginRight: theme.spacing(0),
    gap: theme.spacing(4),
    margin: theme.spacing(3, 0, 3, "-22px"),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
      margin: theme.spacing(3, 0, 3, 0),
    },
  }));

  return (
    <Context.Consumer>
      {({ handleLanguageChange, language }) => (
        <NavbarContainer>
          <Box
            sx={{
              gap: "2.5rem",
              position: "fixed",
              width: "100%",
              background: "#F3FBFF",
              height: "50px",
              zIndex: 10,
            }}
          >
            <Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                {isMobile && (
                  <img
                    src={Catarsis}
                    alt="Catarsis"
                    style={{ width: "40%", margin: "10px" }}
                  />
                )}
                <CustomMenuIcon onClick={toggleDrawer("right", true)} />
              </div>
              <Drawer
                anchor="right"
                open={mobileMenu["right"]}
                onClose={toggleDrawer("right", false)}
              >
                {list("right")}
              </Drawer>
            </Box>

            <NavbarLinksBox>
              <a href="#heroSection" style={{ textDecoration: "none" }}>
                <NavLink variant="body2">{t("navbar.home")}</NavLink>
              </a>
              <a href="#weAre" style={{ textDecoration: "none" }}>
                <NavLink variant="body2">{t("navbar.weAre")}</NavLink>
              </a>
              <a href="#experience" style={{ textDecoration: "none" }}>
                <NavLink variant="body2">{t("navbar.experiences")}</NavLink>
              </a>
              <a href="#thisIsForYou" style={{ textDecoration: "none" }}>
                <NavLink variant="body2">{t("navbar.thisIsForYou")}</NavLink>
              </a>
              <a href="https://shop.mycatarsis.com/" style={{ textDecoration: "none" }}>
                <NavLink variant="body2">{t("navbar.store")}</NavLink>
              </a>
              <a href="/blog" style={{ textDecoration: "none" }}>
                <NavLink variant="body2">{t("navbar.blog")}</NavLink>
              </a>
              <NavLink variant="body2" onClick={handleLanguageChange}>
                <img
                  src={language === "en" ? enflag : esflag}
                  alt="flag"
                  style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                />
                {t("navbar.language")}
              </NavLink>
            </NavbarLinksBox>
          </Box>
        </NavbarContainer>
      )}
    </Context.Consumer>
  );
}
