import React from "react";
import { Context } from "../App";

import {
  Box,
  styled,
  Typography,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import Container from "@mui/material/Container";
import caminos from "../media/card.jpg";
import cardCatarsis from "../media/cardcatarsis.jpg";
import viajeAlCorazon from "../media/elementos-02.png"
import spots from "../media/spots2.png"
import { useTranslation } from "react-i18next";
 
function ExperiencesCard({ title, image, description, calentlyURL, fecha,fecha2}) {
  return (
    <Card sx={{ width: "90%",maxWidth: 400, margin: "10px", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <CardActionArea >
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="Woorkshop image"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" align="center">
            {title.toUpperCase()}
          </Typography>
          <Typography
            variant="body2"
            sx={{ minHeight: "150px" }}
            color="text.secondary"
          >
            {description}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
          >
            <strong>{fecha}</strong> {fecha2}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: "center", alignItems:"flex-end"}}>
        <a  href={calentlyURL} style={{ textDecoration: "none", width: '100%' }}>
          <Button
            sx={{width: "100%"}}
            size="small"
            color="primary"
            variant="outlined"
          >
            Reserva tu espacio
          </Button>
        </a>
      </CardActions>
    </Card>
  );
}

export default function Experiences() {
  const [t, i18n] = useTranslation("global");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(4),
    margin: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "2.5rem",
    color: "#000000",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#000000", 
    fontWeight: "bold",
    fontFamily: "Lato",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  const cardsData = [
    {
      title: "SPOTS TERAPÉUTICOS",
      image: spots,
      description:
        "En CatarsisLab, abrimos nuestros Spots Terapéuticos: espacios exclusivos para quienes buscan acompañamiento psicológico y una intervención precisa, con el mínimo número de encuentros necesarios, pero el máximo impacto posible.Los espacios están disponibles desde hoy. La oportunidad está abierta: si sientes que es tu momento, reserva tu spot ahora.No hace falta esperar a tocar fondo para pedir ayuda. Si lo necesitas, aquí estamos. 💛",
      calentlyURL: "https://shop.mycatarsis.com/products/spots-terapeuticos"
      
    },
    {
      title: "Un Viaje Al Corazón: Music Edition.",
      image: viajeAlCorazon,
      description:
        "​Tienes un nudo en la cabeza, un runrún que no se va… Pues dale la vuelta. Dale un nuevo significado. Convierte ese torbellino mental en… música. 🎶 ​En esta sesión, junto a un productor y compositor (y, por supuesto, Ale & Joan, psicólogas y fundadoras de CatarsisLab), vamos a transformar tu historia en un pedacito de una canción que quedará contigo para siempre.",
      fecha: "Fecha:",
      fecha2: "Sábado, 15 de marzo 2025 | 9:00-14:00",
        
      calentlyURL: "https://lu.ma/9uf7nnrb?from=embed"
    },{
      title: "La conciencia de la cotidianidad",
      image: caminos,
      description:" Un psicólogo te acompañará durante una semana, mientras un creativo trabajará contigo para capitalizar la expresión creativa de lo que en esos dias descubras de ti mismo. Te acompañamos desde tu rutina diaria en el viaje de reflexión y descubrimiento.",
      calentlyURL: "https://calendly.com/catarsislabadm/intro-exp-catarsislab-conciencia-de-la-cotidianidad"
    },
    {
      title: "personaliza tu experiencia",
      image: cardCatarsis,
      description:
        "¡Tu historia nos inspira! Si buscas una experiencia diseñada exclusivamente para ti, puedes contactarnos. Estamos aquí para escucharte, comprender tus necesidades y crear una experiencia terapéutica única para ti. ¡Juntos, vamos a explorar y transformar!",
      calentlyURL: "https://calendly.com/catarsislabadm/30min"
    },
  ];

  return (
    <Context.Consumer>
      {({ setLanguage, theme }) => (
        <div>
          {/* Catarsis lab */}
          <Box
            sx={{
              backgroundColor: "#F1FBFF",
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box sx={{ flex: "1", textAlign: "center" }}>
                  <Title variant="h1">
                  {t("experiences.title")}
                  </Title>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Lato",
                      ml: "10%",
                      mr: "10%",
                    }}
                  >
                    {t("experiences.text1")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F1FBFF",
              mt: -5,
              "@media (max-width: 900px)": {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)", 
              },
              "@media (max-width: 600px)": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            {cardsData.map((card, index) => (
              <ExperiencesCard key={index} {...card} />
            ))}
          </Box>
          <Box
            sx={{
              backgroundColor: "#F1FBFF",
              minHeight: "10vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box sx={{ flex: "1", textAlign: "center" }}>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Lato",
                      ml: "10%",
                      mr: "10%",
                    }}
                  >
                    {t("experiences.text2")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
        </div>
      )}
    </Context.Consumer>
  );
}
